import axios from 'axios';
import authHeader from './auth-header';

const API_URL =  process.env.VUE_APP_BACKEND_URL; 


class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'api/sales/all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'api/sales/user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'api/sales/mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'api/sales/admin', { headers: authHeader() });
  }
}

export default new UserService();
