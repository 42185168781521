import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)






import App from './App.vue';
import { router } from './router';
import store from './store';

import VeeValidate from 'vee-validate';
import Vuex from 'vuex';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt);

Vue.config.productionTip = false;

Vue.use(VeeValidate);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Vuex);

Vue.mixin({
  methods: {

    handleError: function(
      error,
      noAutoHide = false,
      hideAfter = 3000,
      showOnRoot = true
    ) {
      if (error.response) {
        let response = error.response.data;
        if (response.message.type == "string") {
          this.showToast(
            response.message.message,
            response.error,
            "danger",
            noAutoHide,
            hideAfter,
            showOnRoot
          );
        } else {
          for (const [key, value] of Object.entries(response.message.message)) {
            let message = "";
            let count = 0;
            for (const item of value) {
              if (count != 0) {
                message += ", ";
              }
              message += item.message;
              count += 1;
            }
            this.showToast(
              message,
              response.error + " for " + key,
              "danger",
              noAutoHide,
              hideAfter,
              showOnRoot
            );
          }
        }
      } else {
        this.showToast(
          "Unknown error. Please try again.",
          "Error!",
          "danger",
          noAutoHide,
          hideAfter,
          showOnRoot
        );
      }
    },
    showToast: function(
      content,
      title,
      variant,
      noAutoHide = false,
      hideAfter = 3000,
      showOnRoot = true
    ) {
      if (showOnRoot) {
        this.$root.$bvToast.toast(content, {
          title: title,
          noAutoHide: noAutoHide,
          autoHideDelay: hideAfter,
          variant: variant
        });
      } else {
        this.$bvToast.toast(content, {
          title: title,
          noAutoHide: noAutoHide,
          autoHideDelay: hideAfter,
          variant: variant
        });
      }
    },
  }

});
import axios from 'axios';
Vue.prototype.$http = axios;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
